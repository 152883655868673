<template>
  <div>
    <b-overlay
      :show="showOverlay"
      variant="white"
      class="w-100"
    >

      <ubsign-update />

      <qrcode-stream
        class="qr-stream"
        @decode="onDecode"
        @init="onInit"
      >
        <div class="set-vehicle-info-wrapper">
          <b-badge variant="light-secondary" class="bg-white mb-1">
            Inquadra il codice QR del veicolo
          </b-badge>
          <div/>
          <b-badge v-if="vehicleError" variant="danger">
            <span>{{ vehicleError }}</span>
          </b-badge>

          <!--
          <div>
            <b-button
              class="btn-set-vehicle-help bg-white mt-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-set-vehicle-help
              variant="primary"
            >
              <feather-icon
                icon="HelpCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">INSERISCI MANUALMENTE</span>
            </b-button>
          </div>
          -->
        </div>
        <div class="set-vehicle-help-wrapper">
          <b-button
            class="btn-set-vehicle-help bg-white"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-set-vehicle-help
            variant="outline-secondary"
          >
            <feather-icon
              icon="HelpCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">AIUTO</span>
          </b-button>
        </div>
      </qrcode-stream>
    </b-overlay>

    <b-modal
      id="modal-set-vehicle-help"
      modal-class="modal-secondary"
      size="md"
      centered
      ok-only
      ok-variant="outline-secondary"
      ok-title="Chiudi"
      title="SERVE AIUTO?"
    >
      <b-card-text>
        <div>
          <p>Per visualizzare i carichi da consegnare è necessario <strong>impostare un veicolo</strong> inquadrando il
            relativo <strong>QR Code</strong>.</p>
          <p>Se non hai il QR Code richiedilo alla tua sede.</p>
        </div>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import BootstrapVue, {
  BCard, BCardText, BOverlay, BAlert, BBadge, BButton, VBToggle, BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import UbsignUpdate from '../messages/UbsignUpdate'
import { getHomeRouteForVehicleActive } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'

export default {
  components: {
    BOverlay,
    BCardText,
    BAlert,
    BBadge,
    BButton,
    BModal,
    VBModal,
    QrcodeStream,
    UbsignUpdate
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      vehicleID: null,
      vehicleData: null,
      vehicleError: null,
      showOverlay: false,
      error: '',
    }
  },
  methods: {
    onDecode(result) {
      this.showOverlay = true
      store.commit('vehicle/REMOVE_VEHICLE')
      this.$http.get(`/vehicle/info/${result}`)
        .then(response => {
          this.vehicleID = result
          this.vehicleData = response.data.data.vehicle
          console.log(response.data.data.vehicle)
          store.commit('vehicle/SET_VEHICLE', response.data.data.vehicle)
          this.$root.$data.vehicle_active = true
          this.showOverlay = false

          this.$router.replace(getHomeRouteForVehicleActive())
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Mezzo impostato',
                  icon: 'TruckIcon',
                  variant: 'success',
                  text: `${this.vehicleData.ds_automezzo} con targa ${this.vehicleData.targa}. Buon lavoro!`,
                },
              })
            })
        })
        .catch(error => {
          this.showOverlay = false
          store.commit('vehicle/REMOVE_VEHICLE')

          if (error.response) {
            if (error.response.data.message === 'VEHICLE_NOT_FOUND') {
              this.vehicleError = 'Credenziali non valide!'
            } else if (error.response.data.message) {
              this.vehicleError = error.response.data.message
            } else if (error.response.data.statusCode) {
              this.vehicleError = error.response.data.message
            } else {
              this.vehicleError = 'Error'
            }
          }
        })
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permission'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
        } else {
          this.error = `ERROR: Camera error (${error.name})`
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended';

.set-vehicle {
  .qr-stream {

    &.qrcode-stream-wrapper {
      height: calc(100vh - 8rem);
    }

    .qrcode-stream-overlay {
      &:after {
        content: '';
        position: absolute;
        display: block;
        height: 40%;
        width: 80%;
        border: 3px dashed #F8F8F8;
        border-radius: 14px;
        top: 30%;
        left: 10%;
      }

      .set-vehicle-info-wrapper {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 999;
        padding: 1.5rem;
        width: 100%;
        text-align: center;
      }

      .set-vehicle-help-wrapper {
        position: absolute !important;
        bottom: 0;
        left: 0;
        z-index: 999;
        padding: 1.5rem;
        width: 100%;
        text-align: center;

      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .set-vehicle {
    &.navbar-sticky {
      .app-content {
        padding-top: 4.5rem !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    .qr-stream {
      &.qrcode-stream-wrapper {
        height: calc(100vh - 4.5rem);
      }
    }
  }
}
</style>
